







































































































import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import { useDomainApi } from '@/module/api/domain';
import { ExportModel, useExportApi } from '@/module/api/export';
import { LoadJobModel, LoadJobType, LoadJobWizardSetupModel, SourceType, useLoadJobApi } from '@/module/api/load-job';
import router from '@/router';
import { formatDbDate } from '@/utils';
import { computed, defineComponent, reactive, ref, toRefs, unref } from '@vue/composition-api';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const toast = createToastInterface({ maxToasts: 3 });

const { refreshItem: refreshDomain, isLoading: isDomainLoading, selectedItem: selectedDomain } = useDomainApi();

const { getItems: getExportJobs, emptyExportModel } = useExportApi();

const {
  createItem: createLoadJob,
  getItem: getLoadJob,
  emptyLoadJobModel,
  isLoading: isLoadJobLoading,
  setupLoadJob,
  applyWizardSetup,
} = useLoadJobApi();

const emptyState = () => {
  return {
    dialog: false,
    currentDomainUuid: '',
    currentJobUuid: '',
    title: 'Restore Domain',
    isPreparing: false,
    havePrepared: false,
    createdLoadJobViewLink: '',
    label: '',
    validForm: false,
    test: '',
    wizard: {
      step: 1,
      stepsCompleted: 0,
    },
    haveFetchedSourceDomain: false,
    showDeveloperButton: false,
    exportJobs: [] as ExportModel[],
    inputExtractJob: emptyExportModel() as ExportModel,
  };
};

const currentLoadJob = ref<Partial<LoadJobModel>>(emptyLoadJobModel());

const state = reactive(emptyState());

const inputExtractJobHint = computed(() => {
  if (!state.inputExtractJob?.uuid) {
    return '';
  }
  const total = state.inputExtractJob?.objectSummary?.total;
  const date = formatDbDate(state.inputExtractJob?.updatedAt);
  return `${total} objects at ${date}`;
});

const reset = () => {
  Object.assign(state, emptyState());
};

const prepareBaseRestoreJob = async (): Promise<LoadJobModel | undefined> => {
  if (!state.currentJobUuid || state.currentJobUuid == '') {
    return await createLoadJob({
      domainUuid: state.currentDomainUuid,
      type: LoadJobType.RESTORE,
    });
  } else {
    return await getLoadJob({ uuid: state.currentJobUuid, forceRefresh: true });
  }
};

const updateLoadJobWizard = async (baseLoadJob: LoadJobModel): Promise<number | undefined> => {
  // Note: "source" is the restore point we want to get to.
  // "destination" is going to be the most recent extract job on the Domain.
  const updateLoadJobParams: Partial<LoadJobWizardSetupModel> = {
    isComplete: true,
    step: 1,
    source: {
      type: SourceType.DOMAIN,
      uuid: selectedDomain.value.uuid,
      extractJobUuid: state.inputExtractJob.uuid || '',
    },
  };
  return await setupLoadJob(baseLoadJob.uuid, updateLoadJobParams);
};

const finalizeLoadJob = async (loadJobUuid: string) => {
  const res = await applyWizardSetup(loadJobUuid);
  if (!res || (res && res < 200) || res > 299) {
    const errMessage = 'Failed to finalize job';
    Vue.$log.error(errMessage);
    toast.error(errMessage);
    return;
  }
  return res;
};

const prepareJob = async () => {
  Vue.$log.debug({ currentLoadJob });
  if (!state.currentDomainUuid || state.currentDomainUuid === '') {
    const errMessage = 'Not enough information about the current domain to continue';
    Vue.$log.error(errMessage);
    toast.error(errMessage);
    return;
  }

  try {
    state.isPreparing = true;
    const baseLoadJob = await prepareBaseRestoreJob();
    if (!baseLoadJob || !baseLoadJob.uuid) {
      const errMessage = `Failed to create import job for domain ${selectedDomain.value?.domainName}`;
      Vue.$log.error(errMessage);
      toast.error(errMessage);
      return;
    }
    const updateLoadJobResponse = await updateLoadJobWizard(baseLoadJob);
    Vue.$log.debug({ updateLoadJobResponse });

    if (
      !updateLoadJobResponse ||
      (updateLoadJobResponse && updateLoadJobResponse < 200) ||
      updateLoadJobResponse > 299
    ) {
      const errMessage = 'Unable to prepare job setup.';
      Vue.$log.debug(errMessage);
      toast.error(errMessage);
      state.isPreparing = false;
      return;
    }
    const finalizeLoadJobResponse = await finalizeLoadJob(baseLoadJob.uuid);
    Vue.$log.debug('finalized load job response', finalizeLoadJobResponse);
    if (finalizeLoadJobResponse === undefined) {
      const errMessage = 'Unable to finalize job setup.';
      Vue.$log.debug(errMessage);
      toast.error(errMessage);
      state.isPreparing = false;
      return;
    }
    state.createdLoadJobViewLink = `/domain/${state.currentDomainUuid}/job/import/${baseLoadJob.uuid}`;
    state.havePrepared = true;
    state.wizard.step = 2;
    state.wizard.stepsCompleted = 1;

    toast.success('Prepared job.');
  } catch (err) {
    Vue.$log.error(err);
    toast.error('Failed to prepare import job');
  } finally {
    state.isPreparing = false;
  }
};

const selectableExportJobs = computed(() => {
  return unref(state.exportJobs).filter((exportJob: ExportModel) => {
    return exportJob.uuid !== selectedDomain.value?.mostRecentExtractJob?.uuid;
  });
});

const initializeData = async (domainUuid: string, jobUuid?: string) => {
  // TODO: use jobUuid so that we can come back to this if it was already
  // created but not start
  await refreshDomain(domainUuid);
  state.exportJobs =
    (await getExportJobs({
      query: {
        'filter[domainUuid]': selectedDomain.value.uuid,
        limit: '-1',
      },
    })) ?? [];
};

const goToLoadJobDetailPage = () => {
  router.push({ path: state.createdLoadJobViewLink });
};

export default defineComponent({
  name: 'ImportJobRestoreSetupWizard',
  components: { DomainWorkspaceToolbar },
  props: ['uuid', 'jobUuid'],
  setup(props) {
    reset();
    state.currentDomainUuid = props.uuid;
    state.currentJobUuid = props.jobUuid;
    initializeData(props.uuid, props.jobUuid);
    return {
      ...toRefs(state),
      backLink: `/domain/${props.uuid}/workspace`,
      isDomainLoading,
      isLoadJobLoading,
      prepareJob,
      selectedDomain,
      currentLoadJob,
      goToLoadJobDetailPage,
      inputExtractJobHint,
      selectableExportJobs,
    };
  },
});
